// eslint-disable-next-line no-restricted-imports
import {useFlags} from 'launchdarkly-react-client-sdk';

type FeatureFlags = {
  toggleEnableHeatmap: boolean;
  toggleEnableProjectCreation: boolean;
  toggleEnableSimSearch: boolean;
  toggleEnableAnomalyDetectionModel: boolean;
  toggleEnableDeployPage: boolean;
};

const featureFlagKeys: (keyof FeatureFlags)[] = [
  'toggleEnableHeatmap',
  'toggleEnableProjectCreation',
  'toggleEnableSimSearch',
  'toggleEnableAnomalyDetectionModel',
  'toggleEnableDeployPage',
];

const isDevelopmentEnvironment = process.env.NODE_ENV === 'development';

export const useFeatureFlags = () => {
  const flags = useFlags<FeatureFlags>();
  if (isDevelopmentEnvironment) {
    return featureFlagKeys.reduce((acc, key) => ({...acc, [key]: true}), {} as FeatureFlags);
  }
  return flags;
};
