import {Box, List} from '@mui/material';
import {useTranslation} from 'i18n';

import {useFeatureFlags} from '~utils/featureFlags';
import {isInternalEmail} from '~utils/miscUtils';
import {useJuneTrackEvent} from '~hooks/juneAnalytics.hooks';
import {selectUser} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {styled} from 'src/constants/theme';
import {
  ArchiveIcon,
  HeatmapIcon,
  ModelIcon,
  MonitorIcon,
  ProjectsIcon,
  RocketIcon,
  SettingsIcon,
  TasksIcon,
} from 'src/icons';
import {getRoute, Route, RouteOptions} from '../../utils/routeUtil';
import {MainMenuItem} from './MainMenuItem';

const MaddoxAiLogo = styled('img')(({theme}) => ({
  margin: theme.spacing(4, 2.5, 5, 2.5),
}));

export function MainMenu(): JSX.Element {
  const {t} = useTranslation('mainMenu');
  const {toggleEnableHeatmap} = useFeatureFlags();
  const trackEvent = useJuneTrackEvent();
  const currentUser = useAppSelector(selectUser);
  const isInteralUser = isInternalEmail(currentUser?.email);
  const routeOptions: RouteOptions = {keepModelIdQueryParam: false};
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '72px',
        backgroundColor: 'secondary.dark',
      }}
    >
      <MaddoxAiLogo src='/images/maddox-ai-logo.svg' alt='Maddox AI' />
      <List sx={{display: 'flex', flexDirection: 'column', height: '100%', padding: 0}}>
        <MainMenuItem
          onClick={() => trackEvent('main_menu_monitor')}
          linkTo={getRoute(Route.monitor, routeOptions)}
          text={t('monitor')}
          icon={MonitorIcon}
        />
        {toggleEnableHeatmap && (
          <MainMenuItem
            onClick={() => trackEvent('main_menu_heatmap')}
            linkTo={getRoute(Route.heatmap, routeOptions)}
            text={t('heatmap')}
            icon={HeatmapIcon}
            beta
          />
        )}
        <MainMenuItem
          onClick={() => trackEvent('main_menu_models')}
          linkTo={getRoute(Route.models, routeOptions)}
          text={t('models')}
          icon={ModelIcon}
          activePaths={['model']}
        />
        <MainMenuItem
          onClick={() => trackEvent('main_menu_tasks')}
          linkTo={getRoute(Route.tasks, routeOptions)}
          text={t('tasks')}
          icon={TasksIcon}
        />
        <MainMenuItem
          onClick={() => trackEvent('main_menu_archive')}
          linkTo={getRoute(Route.archive, routeOptions)}
          text={t('archive')}
          icon={ArchiveIcon}
        />
        {isInteralUser && (
          <MainMenuItem
            onClick={() => trackEvent('main_menu_deploy')}
            linkTo={getRoute(Route.deploy, routeOptions)}
            text={t('deploy')}
            icon={RocketIcon}
          />
        )}
        <MainMenuItem
          onClick={() => trackEvent('main_menu_settings')}
          linkTo={getRoute(Route.settingsDevices, routeOptions)}
          text={t('settings')}
          icon={SettingsIcon}
        />
        <MainMenuItem
          onClick={() => trackEvent('main_menu_projects')}
          linkTo={getRoute(Route.projects, routeOptions)}
          text={t('projects')}
          icon={ProjectsIcon}
          sx={{marginTop: 'auto', marginBottom: 0}}
        />
      </List>
    </Box>
  );
}
